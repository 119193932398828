import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsofUse = () => (
  <Layout pageInfo={{ pageName: "use" }}>
    <SEO
      title="Terms of Use"
      description="This 'Terms of Use' describes some of the activities that are prohibited when you use our services."
      path="termsofUse"
    />
    <div className="terms">
      <div className="terms-title">Terms of Use</div>

      <div className="text">
        BBA is a business platform that provides businesses with tools and
        technologies to help them build their own online stores and sell
        products and services on the website. We believe that freedom, openness
        and communications are the key principles of business. However, there
        are some activities are incompatible with BBA's mission— to create a
        better e-commerce service. This "Terms of Use" describes some of the
        activities that are prohibited when you use our services.
        <br />
        <br />
        The "materials" mentioned below refer to any photos, images, videos,
        graphics, written content, audio files, codes, information or data
        related to your account or are uploaded, collected, generated, stored,
        displayed, published, transmitted, displayed by your account.
        <br />
        <br />
        The following activities are prohibited:
      </div>
      <div className="title">1. Child exploitation:</div>
      <div className="text">
        You may not provide goods and services which illegally use or abuse
        children's rights, and may not post or upload any of such materials,
        including but not limited to child abuse or sexual abuse, or pictures or
        descriptions which display or introduce children in a sexual way.
      </div>

      <div className="title">
        2. Harassment, bullying, defamation and threats:
      </div>
      <div className="text">
        You may not provide goods or services that harass, bully, defame or
        threaten specific individuals, and you may not publish or upload such
        materials.
      </div>

      <div className="title">3. Malicious content:</div>
      <div className="text">
        You must not use our services to promote or condone discrimination and
        hatred behaviors against race, ethnicity, skin color, national origin,
        religious belief, age, gender, sexual orientation, disability, health
        status, or veteran status, or post or upload such materials. You may
        not use our services to condone or support any organization, platform or
        individual: ( i ) to promote or condone such hatred; or ( ii ) further
        promote such threats or condone such violence.
      </div>

      <div className="title">4. Illegal activities:</div>
      <div className="text">
        You may not provide, publish or upload any goods or services which
        facilitate or promote illegal activities in the jurisdictions you
        located.
      </div>

      <div className="title">5. Intellectual Property:</div>
      <div className="text">
        You may not provide goods or services that infringe the copyrights,
        trademarks, or other intellectual property rights of others, or publish
        or upload such materials.
      </div>

      <div className="title">6. Malicious and deceptive practices:</div>
      <div className="text">
        You must not use BBA services to spread malware or host phishing pages.
        You must not conduct activities which will damage or destroy the service
        operation or other infrastructures of BBA or BBA's third party
        collaborators, nor upload or release such materials. You may not use
        this service for deceptive business activities or any other illegal or
        deceptive activities.
      </div>

      <div className="title">7. Personal and confidential information:</div>
      <div className="text">
        You may not publish or upload any materials which include personal
        identity information, personal sensitive information or confidential
        information (such as credit card numbers, confidential national ID
        numbers, or account password) unless you have obtained the consent from
        the information owner or have been authorized to do so.
      </div>

      <div className="title">8. Self-harm:</div>
      <div className="text">
        You may not provide or promote goods or services related to self-harm,
        nor may you post or upload such materials.
      </div>

      <div className="title">9. Spamming:</div>
      <div className="text">
        You must not use this service to transmit any commercial information
        without the user's permission.
      </div>

      <div className="title">10. Terrorist organizations:</div>
      <div className="text">
        You may not provide goods or services that suggest, promote or sponsor
        terrorist activities, or post or upload such materials, or become a
        member of any terrorist organization.
        <br />
        <br />
        If your activity violates this "Terms of Use" (including your activities
        other than using BBA services), we have the right to delete any
        materials at any time, suspend or terminate your account and your access
        to our services.
        <br />
        <br />
        BBA has the right to supervise or investigate at any time that whether
        the materials and BBA services you use comply with this "Terms of
        Service" and the "Terms of Service" of BBA, or any other consensus or
        agreement reached with BBA (collectively referred to as the
        "Agreement"). Our decision on whether there is any violation of this
        Agreement will be final and binding, and we have the discretion of how
        to deal with violations of this Agreement (including not taking any
        action If BBA user violates the agreement ("defaulting user"), which
        causes BBA to assume third-party compensation liability, BBA reserves
        the right to claim compensation from defaulting user at any time; at the
        same time, BBA reserves the right to require the defaulting user to bear
        the compensation responsibility for the goodwill damage of BBA.
        <br />
        <br />
        In order to provide you with better services and with the development of
        BBA business, this agreement will also be updated accordingly. The
        revised version of the "Terms of Use" will be announced at
        https://www.bigbigads.com/termsofUse or we will use other appropriate
        means to remind you of the update of the relevant content before it
        becomes effective. Please also visit the BBA website to keep abreast of
        the latest use agreement Terms.
        <br />
        <br />
        If there is a conflict between the "Terms of Use" and the "Terms of
        Service", the "Terms of Use" shall prevail, but only to the extent
        necessary to resolve such conflicts. Terms used in this agreement but
        not defined shall be defined according to their meaning in the
        agreement.
      </div>
    </div>
  </Layout>
)

export default TermsofUse
